.datta-scroll,
.scroll-widget,
.main-chat-cont,
.config-scroll,
.main-friend-list {
   .ps__rail-y {
      &:hover > .ps__thumb-y,
      &:focus > .ps__thumb-y,
      &.ps--clicking .ps__thumb-y {
         background-color: #a7a7a7;
         width: 5px;
      }
   }
   .ps__thumb-y {
      background-color: #aaa;
      border-radius: 6px;
      width: 5px;
      right: 0;
   }
}

perfect-scrollbar.datta-scroll > .ps,
perfect-scrollbar.scroll-widget > .ps,
perfect-scrollbar.main-chat-cont > .ps,
perfect-scrollbar.config-scroll > .ps,
perfect-scrollbar.main-friend-list > .ps {
   &.ps--active-y > .ps__rail-y {
      width: 5px;
      &:hover,
      &.ps--clicking {
         width: 5px;
      }
   }
   &.ps--scrolling-y > .ps__rail-y,
   &.ps--scrolling-x > .ps__rail-x {
      opacity: 0.4;
      background-color: transparent;
   }
   &.ps--active-y > .ps__rail-y {
      &:hover,
      &.ps--clicking {
         width: 5px;
      }
   }
}
