@import "~react-perfect-scrollbar/dist/css/styles.css";

@import "ngx-perfect-scrollbar";
@import "react-full-screen";

@import "react-bootstrap/react-bootstrap";

.dropzone {
   .dz-progress {
      display: none;
   }
}
