.fsize-12r {
   font-size: 1.2rem;
}

.visitor-card-body {
   padding: 20px !important;
}

.user-activity-btn {
   margin-right: 0px !important;
}

.user-activity-chevron {
   margin-left: 5px;
   margin-right: 5px;
}

button.btn-green {
   background-color: limegreen;
   border-color: limegreen;
   &:hover {
      background-color: green;
      border-color: green;
   }
}

/* PNotify override */
[data-pnotify] .brighttheme-text {
   line-height: 24px !important;
}

[data-pnotify] .brighttheme-error {
   background-image: none !important;
}

.card {
   &.card-highlight {
      border: 2px solid;
   }
   .card-header-right-menu {
      float: right;
      padding: 0;
      clear: both;

      .dropdown-menu {
         margin-top: 0;

         li {
            a {
               font-size: 14px;
            }
         }
      }

      .btn.dropdown-toggle {
         border: none;
         background: transparent;
         box-shadow: none;
         color: #888;

         i {
            margin-right: 0;
         }

         &:after {
            display: none;
         }

         &:focus {
            box-shadow: none;
            outline: none;
         }
      }
   }
}

/*-----react-autosuggest------*/
.react-autosuggest__container {
   position: relative;
   flex: 1 1 auto;
}

.react-autosuggest__suggestions-container--open {
   display: block;
   position: absolute;
   top: 51px;
   min-width: 280px;
   border: 1px solid #aaa;
   background-color: #fff;
   font-family: Helvetica, sans-serif;
   font-weight: 300;
   font-size: 16px;
   border-bottom-left-radius: 4px;
   border-bottom-right-radius: 4px;
   z-index: 2;
}

.react-autosuggest__suggestions-list {
   margin: 0;
   padding: 0;
   list-style-type: none;
}

.react-autosuggest__suggestion {
   cursor: pointer;
   padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
   background-color: #ddd;
}

/** vertical space **/
.vs-50 {
   margin-top: 50px;
}

/** prevent image drag **/
.drag-none {
   user-drag: none;
   -webkit-user-drag: none;
   user-select: none;
   -moz-user-select: none;
   -webkit-user-select: none;
   -ms-user-select: none;
}

/** print css **/
@media print {
   .printable {
      padding: 50px;
      .col-md-1 {
         width: 8%;
         float: left;
      }
      .col-md-2 {
         width: 16%;
         float: left;
      }
      .col-md-3 {
         width: 25%;
         float: left;
      }
      .col-md-4 {
         width: 33%;
         float: left;
      }
      .col-md-5 {
         width: 42%;
         float: left;
      }
      .col-md-6 {
         width: 50%;
         float: left;
      }
      .col-md-7 {
         width: 58%;
         float: left;
      }
      .col-md-8 {
         width: 66%;
         float: left;
      }
      .col-md-9 {
         width: 75%;
         float: left;
      }
      .col-md-10 {
         width: 83%;
         float: left;
      }
      .col-md-11 {
         width: 92%;
         float: left;
      }
      .col-md-12 {
         width: 100%;
         float: left;
      }
   }
}

.center {
   margin-left: auto;
   margin-right: auto;
   margin-top: 3px;
   margin-bottom: 3px;
   text-align: center;
   display: flex;
   justify-content: center;
}
