.pcoded-header {
   .dropdown {
      height: 70px;
      .dropdown-toggle {
         margin: -2px 0 0 4px;
      }
   }
   .btn {
      padding: 0;
      margin-bottom: 0;
      margin-right: 0;
      border: none;
      &:after {
         right: -12px !important;
      }
      &:focus,
      &:active,
      &:hover {
         text-decoration: none;
      }
   }
   .main-search {
      margin-left: 5px;
      .input-group-text {
         line-height: 1;
      }
   }

   .btn > i {
      margin-right: -12px;
   }
}
